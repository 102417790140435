<template>
  <div>
    <!-- Add a bold button -->
    <button class="ql-bold">
      Bold
    </button>
    <button class="ql-italic">
      Italic
    </button>
    <button class="ql-underline">
      Underline
    </button>
    <button class="ql-link">
      Link
    </button>
    <button
      class="ql-align"
    >
      Align
    </button>
    <button
      class="ql-align"
      value="center"
    >
      Align
    </button>
    <button
      class="ql-align"
      value="right"
    >
      Align
    </button>
    <button
      class="ql-list"
      value="ordered"
    />
  </div>
</template>

<script>
export default {
  name: 'EditorToolbar',
}
</script>
